/* Container for layout */
/* Sidebar */
.sidebar_section {
  width: 15%;
  transition: all 0.5s;
  background-color: #f0f0f0;
}
.header_container {
  height: 55px;
}
.header_container .menu_bar {
  cursor: pointer;
}
/* Content Area */
.content_area {
  width: 85%;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  transition: all 0.5s;
}

/* Header */
.header_section {
  position: fixed;
  width: 85%;
  margin-left: 15%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: all 0.5s;
  z-index: 999;
}
/* Footer */
.footer_section {
  position: fixed;
  bottom: 0;
  color: #90a4ae;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: all 0.5s;
  height: 50px;
  margin-left: 15%;
  padding-top: 8px;
  z-index: 999;
}
.header_section,
.footer_section {
  position: fixed;
  width: 85%;
  margin-left: 15%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: all 0.5s;
  z-index: 999;
}
.footer_section .footer span {
  color: #616161;
}
.sidenav__section {
  position: fixed;
  height: 100%;
  width: 15%;
  background: #1d2b36;
  padding: 15px 7px 15px 12px;
  z-index: 99;
  font-size: 14px;
  align-items: center;
  transition: all 0.5s;
}
.sidenav__section .logo_section span {
  font-weight: 600;
  font-size: 20px;
}
.sidenav__section .logo_section {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.sidenav__section .logo_section img {
  width: 30px;
  margin: 0 8px;
}

.sidenav__section .logo_section .logoTitle {
  font-size: 20px;
  margin-left: 5px;
}

.sidenav__section a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}

.menu-content::-webkit-scrollbar {
  display: none;
}

.menu-title {
  color: #fff;
  font-size: 14px;
}
.select_company {
  padding-right: 20px;
}
.custom_select.ant-dropdown-menu {
  top: 18px;
  width: 135px;
  padding: 6px;
  right: 75px;
}
.logout_select.ant-dropdown-menu {
  top: 15px;
  width: 125px;
  padding: 5px 8px;
  border-radius: 6px;
}
.header_section .menu_title,
.header_section .company_name {
  font-weight: 600;
}
.layout_container {
  height: 100vh;
  transition: width 0.3s ease;
}

.navbar,
.main {
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  z-index: 1000;
}

.sidenav__section.close ~ .navbar,
.sidenav__section.close ~ .main {
  left: 0;
  width: 100%;
}

.navbar {
  position: fixed;
  color: #fff;
  padding: 15px 20px;
  font-size: 25px;
  background: #f3f3f3;
  cursor: pointer;
}

.navbar #sidebar-close {
  cursor: pointer;
}

.main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 100;
  background: #e7f2fd;
}

.main h1 {
  color: #11101d;
  font-size: 40px;
  text-align: center;
}
.header_section .ant-avatar-sm.ant-avatar-icon {
  font-size: 20px;
}
.sidebar_section .menu-items {
  list-style: none;
  padding: 0;
}
.sidebar_section .menu-items .item {
  cursor: pointer;
  white-space: nowrap;
}
.sidebar_section .menu-items .item svg,
.header_right svg {
  vertical-align: baseline;
}
.menu-items .item .full-width-link {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.full-width-link:hover {
  background-color: #f0f0f0;
}
.layout_container.sidebar_open .sidenav__section {
  width: 5%;
  transform: translateX(0);
}
.layout_container.sidebar_open .content_area,
.layout_container.sidebar_open .header_section,
.layout_container.sidebar_open .footer_section {
  width: 95%;
  margin-left: 5%;
  transform: translateX(0);
  transition: all 0.5s;
  z-index: 1;
}
.layout_container.sidebar_open .logoTitle,
.layout_container.sidebar_open .menu-items-content {
  display: none;
}
.layout_container.sidebar_open .menu-items {
  font-size: 23px;
}
.sidebar_section .sidenav__section a:hover,
.sidebar_section .sidenav__section a:active {
  color: #fff;
}
.menu_icon {
  display: none;
}

.header_section
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #fff;
}
.header_section
  .ant-select:not(.ant-select-disabled):hover
  .ant-select-selector {
  border-color: #fff;
}
.content_area_section {
  margin: 4% 0% 2% 0%;
}
.menu_icons {
  padding: 0.5rem;
  font-size: 20px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-select-selector {
  border-color: #fff;
}
.Select:focus {
  outline: none;
}
.sidebar__close {
  display: none;
}
.header_right .ant-dropdown-trigger {
  cursor: pointer;
}
.custom_select .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  font-size: 15px;
  color: #2a2e30;
}
.footer_section p {
  padding-left: 15px;
}
.user_img {
  width: 26px !important;
  height: 26px !important;
}
.mobileiCons {
  font-size: 20px !important;
  padding: 0.5rem !important;
}
.user_letter_profile {
  background-color: #820080;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: block;
  padding: 4px 6px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}
.side_menu {
  background: #1d2b36 !important;
  border: none !important;
}
.side_menu .ant-menu-submenu {
  font-size: 15px;
  color: #dadada;
}
.side_menu .ant-menu-submenu-title {
  padding-left: 10px !important;
}
.elite-icon {
  width: 23px;
  height: 23px;
}
.side_menu .ant-menu-submenu-arrow {
  color: #fff !important;
}
.side_menu ul {
  background: #1d2b36 !important;
  border: none !important;
}
.side_menu li ul li a {
  color: #fff;
}
.menu-content ul li:hover {
  background-color: #303750;
  color: #24abe2;
}
.menu-content ul li:active {
  background-color: #303750;
  color: #24abe2;
}
.side_menu .ant-menu .ant-menu-item-selected {
  background-color: #303750 !important;
  color: #24abe2 !important;
}
.side_menu .ant-menu .ant-menu-item-selected:hover,
.side_menu .ant-menu .ant-menu-item-selected:active,
.side_menu .ant-menu .ant-menu-item-selected > span > a {
  background-color: #303750 !important;
  color: #24abe2 !important;
  display: block;
  text-decoration: none;
  white-space: pre-wrap;
}

.side_menu .ant-menu .ant-menu-item-only-child > span > a:hover {
  color: #24abe2 !important;
}

.ant-menu-light .ant-menu-submenu-title:hover {
  background-color: #303750 !important;
  color: #24abe2 !important;
}

.ant-menu-light .ant-menu-submenu-open .ant-menu-submenu-title {
  background-color: #303750 !important;
  color: #24abe2 !important;
}

.ant-menu-light .ant-menu-submenu-open .ant-menu-inline .ant-menu-item:after {
  border-right: 0px !important;
}

.ant-menu-light .ant-menu-item:hover {
  background-color: #303750 !important;
  color: #24abe2 !important;
}
.menu-content .ant-menu-inline-collapsed {
  width: 100% !important;
}
.side_menu .ant-menu-submenu .ant-menu-submenu-title span {
  font-size: 16px;
}
.side_menu li ul li {
  padding-left: 38px !important;
  height: 35px !important;
}
.mobile__menubtn {
  display: none;
}
.side_menu .ant-menu-submenu .ant-menu-sub .ant-menu-item-only-child{
  padding: 0;
}
.user_profile{
  text-transform: capitalize;
}
/* layout.css */
@media (max-width: 767px) {
  .sidenav__section {
    left: -20rem;
  }
  .layout_container.sidebar_open .sidenav__section {
    left: 0rem;
  }
  .sidebar__close {
    background: #1d2b36;
    border: none;
    color: #fff;
    display: block;
    font-size: 15px;
    padding: 10px 15px;
    position: absolute;
    right: -8%;
    top: 0;
    z-index: 3;
  }
  .layout_container.sidebar_open .menu-items-content,
  .layout_container.sidebar_open .logoTitle {
    display: block;
    font-size: 14px;
  }
  .menu_icon {
    display: block;
  }
  /* .header_container .menu_title {
    display: block !important;
  } */
  .content_area_section {
    margin-top: 6%;
  }

  .header_right .select_company,
  .header_right .profile_section {
    display: none;
  }

  .layout_container .header_section,
  .layout_container .content_area,
  .layout_container .footer_section,
  .layout_container.sidebar_open .content_area,
  .layout_container.sidebar_open .header_section,
  .layout_container.sidebar_open .footer_section {
    width: 100%;
    margin-left: 0%;
  }
  .layout_container.content_area {
    margin-top: 12%;
  }
  .layout_container.sidebar_open .sidenav__section {
    width: 55%;
    transition: all 0.5s;
    z-index: 999;
  }

  .close__btn.sidebar_open .sidenav__section {
    width: 60%;
  }
  .header_section .menu_title,
  .header_section .company_name,
  .header_right {
    font-size: 14px;
  }

  .sidenav__section .logo_section .logoTitle {
    font-size: 16px;
  }
  .desktop__menubtn {
    display: none;
  }
  .mobile__menubtn {
    display: block;
  }
}
/* Responsive css  */
@media (min-width: 768px) and (max-width: 1366px) {
  .layout_container.sidebar_open .sidenav__section {
    width: 6%;
  }
  .sidenav__section a {
    font-size: 12px;
    white-space: pre-wrap;
  }
  .sidenav__section .logo_section .logoTitle {
    font-size: 14px;
    margin-left: -5px;
  }
  .sidenav__section .logo_section img {
    margin: 0 6px;
  }
  .home_icon {
    font-size: 20px;
  }
  .header_left .menu_title {
    font-size: 14px;
  }
  .custom_select.ant-dropdown-menu {
    left: -70px;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .sidenav__section a {
    font-size: 11px;
    white-space: pre-wrap;
    line-height: 22px;
  }
  .layout_container.sidebar_open .sidenav__section {
    width: 9%;
    padding: 15px;
  }
  .layout_container.sidebar_open .content_area,
  .layout_container.sidebar_open .header_section,
  .layout_container.sidebar_open .footer_section {
    width: 93%;
    margin-left: 8%;
  }
  .custom_select.ant-dropdown-menu {
    left: -80px;
  }
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .footer_section {
    height: auto;
    font-size: 14px;
    text-align: center;
    padding: 6px 0;
  }
}
@media (max-width: 830px) {
  .w-30 {
    width: 30px;
  }
}

@media (max-width: 994px) {
  .layout_container.sidebar_open {
    margin-left: 0;
  }

  .header_right {
    font-size: 13px;
  }
  .select_company {
    padding-right: 0px;
  }

  .content_area {
    width: 80%;
    margin-left: 20%;
  }
  .header_section,
  .footer_section {
    position: fixed;
    width: 80%;
    margin-left: 20%;
  }
  .sidenav__section {
    width: 20%;
  }
  .content_area_section {
    margin: 6% 0% 2% 0%;
  }
}

@media (max-width: 450px) {
  .sidebar__close {
    right: -15%;
  }
  .header_right,
  .header_right .company_name,
  .header_right .anticon-down {
    font-size: 10px;
    padding: 0px;
  }

  .timeline {
    margin: 0px !important;
  }
  .event-date {
    left: 39.5%;
    top: -32px;
  }
  .header_right {
    font-size: 10px;
    padding: 0px;
  }
  .header_right .select_company {
    padding-right: 14px;
  }
  .header_right .profile_section {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .user_letter_profile {
    height: 25px;
    width: 25px;
    display: block;
    padding: 5px 3px;
    font-size: 11px;
  }
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .footer_section p {
    padding: 10px 15px;
    font-size: 12px;
  }
  .custom_select.ant-dropdown-menu {
    left: -85px;
  }
  .logout_select.ant-dropdown-menu {
    left: -20px;
  }
  .side_menu li .ant-menu-submenu-title span {
    opacity: 1 !important;
  }
  .side_menu li .ant-menu-submenu-arrow {
    opacity: 1 !important;
  }
  .ant-menu-submenu ul li .ant-menu-title-content a {
    text-decoration: none;
  }
}
@media (max-width: 322px) {
  .header_right {
    font-size: 7px;
    padding: 0px;
  }
  .ant-dropdown-trigger > .anticon.anticon-down {
    font-size: 6px;
  }
  .header_right .company_name {
    font-size: 7px;
  }
  .header_right .ant-dropdown-trigger .anticon.anticon-down {
    font-size: 5px;
  }
}
@media (max-width: 320px) {
  .footer_section p {
    font-size: 11px;
  }
  .layout_container.sidebar_open .menu-items-content,
  .layout_container.sidebar_open .logoTitle {
    font-size: 13px;
  }
  .user_letter_profile {
    font-size: 10px;
    padding: 5px 4px;
  }
  .side_menu li .ant-menu-submenu-title span {
    opacity: 1 !important;
  }
  .side_menu li .ant-menu-submenu-arrow {
    opacity: 1 !important;
  }
  .ant-menu-submenu ul li .ant-menu-title-content a {
    text-decoration: none;
  }
}
