.card-hover.hovered {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.config-table .ant-table-tbody > tr > td {
  border-bottom: 0;
}

.drawer-content .ant-select {
  width: 100%;
}

.drawer-select .ant-select .ant-select-selector {
  border-radius: 20px;
  overflow-y: auto;
}

.drawer-select .ant-select .ant-select-selector::-webkit-scrollbar {
  width: 6px;
}

.drawer-select .ant-select .ant-select-selector::-webkit-scrollbar-thumb {
  background-color: #1883b8;
  cursor: pointer;
}

.drawer-select .ant-select .ant-select-selector::-webkit-scrollbar-thumb:hover {
  background-color: #24abe2;
}

.main-setting .setting-button {
  position: fixed;
  display: block;
  right: 0.8px;
  top: 42%;
  z-index: 1051;
  width: 54px;
  color: #f1f1f1;
  height: 50px;
  background: #da4453;
  border: none;
  border-radius: 15px 0 0 15px;
  transition: right 0.3s ease;
}

.main-setting .setting-button.open {
  right: 430px;
  z-index: 1051;
}
.main-setting .setting-button .anticon-spin {
  display: inline-block !important;
}

.dashboard .ant-btn:not([disabled]):hover,
.main-setting .ant-btn:focus {
  background: #c42737;
  color: #f1f1f1;
}

.global_filter .ant-drawer-content-wrapper {
  width: 430px !important;
}
.global_filter .ant-btn.ant-btn-default {
  border: none;
}
.filter_dropdown {
  padding: 0 1.5rem;
}
.filter_dropdown .ant-select-selector {
  height: 40px !important;
  font-size: 15px;
  padding: 5px 8px !important;
}
.filter_dropdown .ant-select-multiple .ant-select-selection-placeholder {
  left: 15px;
}
.global_filter .ant-drawer-content .ant-drawer-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.global_filter .ant-drawer-content .ant-drawer-header .ant-drawer-title {
  font-size: 20px;
  font-weight: 600;
  color: #5f5f5f;
}
.fliter_close {
  font-size: 18px;
  line-height: 15px;
}
.filterLbl {
  padding-top: 15px;
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 550;
  color: #5f5f5f;
}
.global_filter_btn {
  display: flex;
  justify-content: space-between;
  padding: 6rem 1.5rem;
}
.filter_btn_dis {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.filter_btn_dis:hover,
.filter_btn_dis:active {
  background: #da4453 !important;
}

@media (max-width: 768px) {
  .global_filter .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .main-setting .setting-button {
    z-index: 1 !important;
    position: fixed;
  }
}

@media (max-width: 1440px) {
  .main-setting .setting-button {
    z-index: 1111;
    position: fixed;
  }
}
