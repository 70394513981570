* {
  margin: 0;
  padding: 0;
}
.login_pg {
  background-image: url("../../assets/images/KnackBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  align-content: center;
  object-fit: cover;
}
.nav-item button {
  padding: 0.5rem 1rem;
}
.login_tabs .nav-link {
  color: #3bafda;
  width: 100%;
}
.login_tabs .nav-tabs {
  display: flex;
  justify-content: center;
}
.nav-link:hover {
  color: #3bafda !important;
}
.nav-link.active {
  color: #fff !important;
  background-color: #3bafda !important;
  border-radius: 0.21rem !important;
}
.form-group {
  padding: 10px 0px;
}
.form_section {
  padding: 10px;
}
.btn-block {
  width: 100%;
}
.ant-input-affix-wrapper-lg {
  border-radius: 3px;
}
.ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
}

.form_button button {
  color: #3bafda !important;
  background-color: #fff !important;
  border-radius: 0.21rem;
  border: 1px solid #3bafda;
}
.ant-form-item-explain-error {
  text-align: left;
}
.nav-tabs .nav-link:hover {
  border-color: #fff !important;
}
.login_form .ant-input-affix-wrapper {
  border-radius: 8px;
}
.login_btn {
  height: 42px !important;
  border-radius: 8px !important;
}
.login-block .card-body {
  padding: 0 15px 0px;
}
@media (min-width: 768px) and (max-width: 1366px) {
  .login-block {
    width: 550px !important;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .nav-item .nav-link {
    font-size: 12px;
  }

  .ant-input-affix-wrapper-lg ::placeholder {
    font-size: 14px;
  }

  .login-block {
    width: 500px !important;
  }
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .login-block {
    width: 500px !important;
  }
}

@media screen and (min-width: 370px) and (max-width: 670px) {
  .login-block {
    width: 370px !important;
  }
}
@media (max-width: 320px) {
  .login-block {
    width: 300px !important;
  }
}
