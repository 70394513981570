body {
  background-color: #f1f1f1;
}
.cPointer {
  cursor: pointer;
}
.bg_info {
  background-color: #3bafda;
}
.bg_warning {
  background-color: #f6bb42;
}
.bg_danger {
  background-color: #da4453;
}
.bg_success {
  background-color: #37bc9b;
}
.white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}
.bg-info {
  background-color: #3bafda;
}
.info {
  color: #3bafda;
}
.orange {
  color: #ff9800;
}
.bg-orange {
  background-color: #ff9800 !important;
}
.pink {
  color: #e91e63 !important;
}
.bg-pink {
  background-color: #e91e63 !important;
}
.green {
  color: #4caf50 !important;
}
.bg-green {
  background-color: #4caf50 !important;
}
.purple {
  color: #8e24aa !important;
}

.bg-purple {
  background-color: #8e24aa !important;
}
.blue {
  color: #2196f3 !important;
}

.bg-blue {
  background-color: #2196f3 !important;
}
.border-blue {
  background-color: #2196f3 !important;
}

.content .content-wrapper {
  padding: 2rem 1.5rem;
}

.content .content-wrapper .content-header-title {
  font-weight: 600;
  letter-spacing: 1px;
  color: #5f5f5f;
  font-size: 20px;
  margin-bottom: 1rem;
}
.card {
  margin-bottom: 1.875rem;
  border: none;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
}
.fullScreenCard {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
}
.border-right-white {
  border-right: 1px solid #fff;
}
.font24 {
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-body {
  padding: 15px;
}

/* font size */
.ft-12 {
  font-size: 12px;
}
.ft-14 {
  font-size: 14px;
}
.ft-16 {
  font-size: 16px;
}
.ft-18 {
  font-size: 18px;
}
.ft-20 {
  font-size: 20px;
}
/* margin css */
.m-0 {
  margin: 0;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-0 {
  margin-right: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

/* padding css */
.p-0 {
  padding: 0;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.pt-0 {
  padding-top: 0;
}
.pt-8 {
  padding-top: 8px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

/* text css*/
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* flex css */
.no-wrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-left {
  justify-content: left;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-around {
  justify-content: space-around;
}

/* <---------- Dashboard css section ----------> */
/* Scrolling css start */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
::-webkit-scrollbar:horizontal {
  height: 12px;
  box-shadow: inset 0 0 5px grey;
}
::-webkit-scrollbar-thumb:horizontal {
  background: grey;
  border-radius: 10px;
}
/* Scrolling css end */
.kanck_logo {
  margin-top: 15px;
}
.dash_cards {
  min-height: 225px;
  cursor: pointer;
}
.dash_cards .cards {
  border-bottom: 2px solid #bcbcbc;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
  text-align: start !important;
}
.card_counts h5 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}
.card_counts h6 {
  font-size: 14px;
}
.dash_cards .card_lbl {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 15px;
  font-weight: 500;
  color: #4f4f4f;
}
.dash_cards .card_lbl .Lbold {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.o2w_card {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.card_small_img {
  width: 22px;
  margin-right: 2px;
  margin-bottom: 5px;
}
.loader .ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: #da4453;
}
.loader .ant-spin .ant-spin-text {
  color: #da4453;
}
.order.images,
.Materials.images_bg,
.Quantity .images_bg,
.MTQuantity .images_bg,
.logisticDelivered.images_bg,
.logisticTransit.images_bg,
.logisticPending.images_bg,
.logisticTotal.images_bg {
  position: relative;
}

.order.images_bg::before {
  content: "";
  background: url("../images/OrderBg.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 50%;
  height: 80%;
  opacity: 0.2;
}
.Materials.images_bg::before {
  content: "";
  background: url("../images/white_M.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 0px;
  right: 1px;
  width: 50%;
  height: 80%;
  opacity: 0.2;
}
.Quantity.images_bg::before {
  content: "";
  background: url("../images/White_Q.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 10px;
  right: 0px;
  width: 50%;
  height: 80%;
  opacity: 0.2;
}
.MTQuantity.images_bg::before {
  content: "";
  background: url("../images/White_MTQ.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 8px;
  right: 1px;
  width: 50%;
  height: 80%;
  opacity: 0.2;
}

/* Dashboard tabs section---------------->> */
.dash_card_no {
  font-size: 18px;
  color: #fff;
}

.material_tabs .ant-tabs-tab-active {
  background-color: #fff !important;
}

.dashboard_tabs .ant-tabs .ant-tabs-nav::before {
  border-bottom: 1px solid #adb5bd;
}

.dashboard_tabs .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  background: transparent;
  border: none;
  color: #3bafda;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px 8px 0 0;
}
.dashboard_tabs .ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background: transparent;
  border: 1px solid transparent;
  border-color: #adb5bd #adb5bd #f1f1f1;
  border-radius: 8px 8px 0 0;
  font-weight: 600;
}
.dashboard_tabs .ant-tabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4e5154;
}
.dashboard_tables .ant-table .ant-table-content .ant-table-thead > tr > th {
  position: relative;
  color: #55595c;
  font-weight: 600;
  text-align: left;
  background: #fff;
  border-bottom: 1px solid #e3ebf3;
  font-size: 15px;
  padding: 12px 14px;
  cursor: pointer;
}
.dashboard_tables .ant-table .ant-table-content .ant-table-tbody > tr {
  background-color: rgba(245, 247, 250, 0.5);
  text-decoration: none;
}
.dashboard_tables .ant-table .ant-table-content .ant-table-tbody > tr > td {
  background-color: #fff;
  text-decoration: none;
}
.dashboard_tables
  .ant-table
  .ant-table-content
  .ant-table-thead
  > tr
  > th:last-child {
  cursor: default;
}
.dashboard_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 14px 15px;
  text-decoration: none;
}
.dashboard_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell p {
  margin-top: 0;
  margin-bottom: 0;
}
.tab_searchtxt {
  padding-bottom: 10px;
}
.tab_searchtxt .ant-input-affix-wrapper {
  padding: 5.5px 10px;
  font-size: 16px;
  border-top-left-radius: 18px !important;
  border-bottom-left-radius: 18px !important;
}
.tab_searchtxt .ant-input-search-large .ant-input-search-button {
  height: 38px;
  border-radius: 0 18px 18px 0 !important;
}
.tab_searchtxt .ant-input-group-wrapper {
  width: 200px;
}
.tab_report_searchtxt {
  padding-bottom: 10px;
}
.tab_report_searchtxt .ant-input-affix-wrapper {
  padding: 6px 10px; /* 5.5px 10px; */
  font-size: 16px;
  border-top-left-radius: 18px !important;
  border-bottom-left-radius: 18px !important;
}
.tab_report_searchtxt .ant-input-search-large .ant-input-search-button {
  height: 38px;
  border-radius: 0 18px 18px 0 !important;
}
.tab_report_searchtxt .ant-input-group-wrapper {
  width: 200px;
}
.tbl_info_details {
  text-align: center;
  cursor: pointer;
}
.tbl_info_details .anticon-info-circle {
  font-size: 16px;
}

/* Details Overview CSS */
.dashboard .ant-select {
  width: 50%;
  border-radius: 20px;
}

.dashboard .ant-select-selector {
  border-radius: 10px !important;
  width: 50%;
}

.text-purple {
  color: #967adc;
}

.text-orange {
  color: #ff9800;
}

/* ------------Timeline tab CSS------------ */
.timeline {
  margin-right: 1rem;
  margin-left: 1rem;
}
.timeline-with-icons {
  border-left: 2px solid #939597;
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
  z-index: 1;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}
.ant-modal-content .hideIcon {
  display: none;
}
.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  top: 20px;
  background-color: hsl(217, 88.2%, 90%);
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}
.timeline-item .timeline-icon:hover {
  left: -52px;
  top: 15px;
  transform: scale(1.1);
  width: 35px;
  height: 35px;
  transition: all ease 0.3s;
}

.timeline-item::before {
  content: " ";
  position: absolute;
  top: 25px;
  z-index: 0;
  left: -10px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.timeline .timeline-item .card-header {
  background-color: #fff;
}
.timeline .timeline-item h6 {
  font-size: 13px;
}
.timeline .timeline-item .ant-table-thead > tr > th {
  font-weight: 600;
  padding: 14px;
}
.timeline .timeline-item .card-header {
  padding: 5px 10px;
}
.timeline .timeline-item .ant-table-pagination-right {
  padding: 0px 10px;
}
.timelineImage {
  width: 14px;
  margin: 10px;
}
.timeline .event-date {
  position: absolute;
  top: -38px;
  left: -63px;
  padding: 5px;
  min-width: 66px;
  margin: 0 auto;
  z-index: 2;
}
.main-timeline {
  padding-top: 0;
}
.global_filter {
  display: flex;
  justify-content: space-between;
}
.global_filter .ant-btn {
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
  height: 36px;
  margin-bottom: 15px;
}
.global_filter .ant-btn:hover {
  color: #000;
}
.global_filter .apply_flt {
  background: #3bafda;
  border-color: #3bafda;
}
.global_filter .apply_flt:hover {
  color: #fff;
}
.global_filter .reset_flt {
  background: #da4453;
  border-color: #da4453;
}
.global_filter .reset_flt:hover {
  color: #fff;
}
.dash_search .ant-input-search-button:hover {
  background: transparent !important;
  border-color: #d9d9d9 !important;
}
.cust_po_no_lbl {
  color: #3bafda;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.details_breadcrum {
  margin: 0px 0px 10px;
}
.details_breadcrum label {
  margin: 0px 0px 10px;
  font-size: 15px;
  font-weight: 600;
}
.details_breadcrum {
  text-transform: capitalize;
}
.details_breadcrum .ant-breadcrumb-link:hover {
  background-color: rgba(0, 0, 0, 0.06);
  padding: 4px;
  border-radius: 6px;
}
.details_breadcrum .ant-breadcrumb-link a {
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}
.details_view_title {
  display: flex;
  justify-content: space-between;
}
.details_view_title .back_btn {
  border-radius: 8px;
  height: 38px;
  font-size: 16px;
  margin-bottom: 15px;
}
.details_view_title .back_btn:hover {
  border-color: #1890ff !important;
  background: #1890ff !important;
}
.details_view_title .back_btn .anticon-double-left svg {
  margin-bottom: 5px;
}

.custom-label {
  width: 18%;
}

.sign-logo {
  height: 22%;
  margin: 20px;
}

.material_count {
  margin-left: 10px;
  white-space: nowrap;
}
.details_collapse {
  width: 100%;
  border-radius: 10px;
}
.details_collapse .ant-collapse-item:last-child {
  border-radius: 10px !important;
}
.details_collapse
  .ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 16px;
  font-weight: 600;
}
.details_collapse .ant-collapse-content-box {
  padding: 0 10px;
}
.details_collapse_body {
  min-height: 350px;
  max-height: 350px;
  overflow: auto;
  overflow-x: hidden;
}
.card_padding {
  padding: 0 12px;
}
.sign_logos {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  align-items: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.invoice_card_no label {
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
}
.delay_status label {
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
  background-color: #dc3545;
  display: inline-flex;
}

.delay_status label p {
  font-weight: bold;
}
.early_status label {
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
  background-color: #90ee90;
  display: inline-flex;
}

.early_status label p {
  font-weight: bold;
}
.delay_status_code label {
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
  display: -webkit-inline-box;
}

.delay_status_code label p {
  font-weight: bold;
}
.mb-15 {
  margin-bottom: -15px;
}
.tl_mater_Img {
  width: 18px;
  margin: 10px;
}
.bL_status_color label {
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
  background-color: #3bafda;
  display: inline-flex;
}
.mate_invoice_color label {
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
  background-color: hsl(217, 88.8%, 35.1%);
  display: inline-flex;
}
.delay_red {
  background-color: #dc3545;
}
.delay_green {
  background-color: #51c5a8;
}
.early_green {
  background-color: #90ee90;
}
.knack_delay_status {
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  margin: 0;
  padding: 0 4px;
  margin-left: 5px;
}
.knack_delay_status p {
  margin: 0;
  font-weight: bold;
}
.lbl_flex {
  display: flex;
}
.fnt_w_bold {
  font-weight: bold;
}
.lbl_flex p {
  font-weight: bold;
  margin: 0;
  padding-left: 2px;
}
.card_text_img_size img {
  width: 85%;
}
.delivery_chart_data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wid_10 {
  width: 10%;
}
.wid_90 {
  width: 90%;
}
.scroll-to-top {
  position: fixed;
  opacity: 1;
  bottom: 4rem;
  right: 1rem;
  z-index: 111;
}
.scroll-to-top img {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.cur_point {
  cursor: pointer;
}
.cur_point h4 {
  font-size: 20px;
  text-align: end;
}
.overCard_img {
  width: 40px;
  height: 40px;
}
.mr_top {
  margin-top: 1.5rem;
}
.upload_documents {
  width: 480px;
}
.upload_documents .ant-modal-content .ant-modal-body {
  padding: 10px 25px;
}
.upload_documents .ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: bold;
}
.upload_docts {
  float: right;
  margin-right: 1rem;
  margin-top: -3px;
  background: #2196f3;
  font-weight: 600;
}
.upload_docts:hover {
  background: #2196f3 !important;
  border-color: #2196f3 !important;
}
.upload_docts span svg {
  margin-top: -6px;
}
.upload_doc_select label {
  padding-right: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 550;
  color: #5f5f5f;
}
.upload_doc_select .ant-select .ant-select-selector {
  border-radius: 8px;
}
.dis_flex {
  display: flex;
}
.lbl_pad {
  padding: 0 10px;
}
.upload_doc_select {
  display: grid;
}
.upload_doc_mdl .col-lg-6 {
  flex: 1 1 100% !important;
}
.drop_box {
  margin: 10px 0;
  padding: 15px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}
.drop_box h4 {
  font-size: 18px;
  font-weight: 400;
  color: #2e2e2e;
}
.drop_box p {
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}
.file_upload_btn {
  text-decoration: none;
  background-color: #2196f3;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 10px;
  transition: 0.3s;
  height: 40px;
  line-height: 10px;
}
.file_upload_btn:hover,
.file_upload_btn:focus {
  text-decoration: none;
  background-color: #2196f3;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-color: #2196f3;
  outline: 1px solid #2196f3;
}

.file_info {
  display: flex;
}

.file_info span {
  margin-top: 10px;
  font-weight: 600;
}
.file_info img {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.upload_btn {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
}
.upload_btn .ant-btn {
  width: 130px;
  border-radius: 8px;
  height: 35px;
}
.upload_btn .file_submit {
  background-color: #005af0;
  border-color: #005af0;
  color: #fff;
  font-size: 16px;
}
.file_upload_btn span svg {
  margin-top: -5px;
}
.upload_documents .ant-modal-content {
  border-radius: 10px;
}
.upload_documents .ant-modal-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form_error {
  color: #ff4d4f;
}
.download_link_col {
  text-align: center !important;
}
.donw_link {
  text-align: center;
}
.donw_link img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.documents_card_scroll {
  max-height: 450px;
  overflow: auto;
}
.margin_card {
  margin: 1rem 0 0;
}
.content-body .ant-card {
  border-radius: 8px;
}
.content-body .card {
  border-radius: 8px;
}
/* --------Payment Module Deign Start----------------- */
.payment_tab .tab_panel .ant-tabs-nav::before {
  border-bottom: none;
}
.payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab {
  border-radius: 100px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  margin: 0 12px;
  box-shadow: 0px 0px 1px 1px #00000014;
  height: 42px;
  display: flex;
  justify-content: center;
  min-width: 135px;
}
.payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab:first-child {
  margin-left: 0;
}
.payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: #343434;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.payment_tab .tab_panel .ant-tabs-nav .ant-tabs-tab-active {
  background: #536d80;
}
.payment_tab .tab_panel .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.payment_tab .ant-select .ant-select-selector {
  border-radius: 100px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 0px 1px 1px #00000014;
  height: 40px;
  padding: 4px 14px;
  font-size: 14px;
  width: 160px;
  min-width: 160px;
}
.payment_tab .analy_type {
  padding-top: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #1e2c36;
}
.pay_section_1 {
  margin: 20px 0 0 0;
  padding: 15px 25px 0px;
  font-size: 14px;
  position: relative;
  background: #f8f8f8;
  box-shadow: 0px 0px 40px #00000012;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.pay_section_2 {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.pay_section_3 {
  margin: 20px 0 0 0;
  padding: 12px 25px 15px;
  font-size: 14px;
  position: relative;
  background: #f8f8f8;
  box-shadow: 0px 0px 40px #00000012;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.pay_customer_tab .ant-card {
  border-radius: 10px;
  background: #f5f5f5;
  border: none;
  height: 420px;
}
.pay_customer_tab .card_lbl {
  font-size: 20px;
  font-weight: 600;
  color: #1e2c36;
}
.pay_search_int .ant-input-affix-wrapper {
  padding: 5.5px 10px;
  font-size: 16px;
  border-top-left-radius: 18px !important;
  border-bottom-left-radius: 18px !important;
}
.pay_search_int .ant-input-search-large .ant-input-search-button {
  height: 38px;
  border-radius: 0 18px 18px 0 !important;
}
.pay_search_int .ant-input-group-wrapper {
  width: 250px;
}
.pay_search_int .ant-input-group-addon {
  background: #f5f5f5;
}
.pay_search_int .ant-input-affix-wrapper:hover,
.pay_search_int .ant-input-affix-wrapper:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}
.apexcharts-tooltip {
  background: #1e2c36 !important;
  color: #fff;
  border: none !important;
  position: absolute;
  transform: translateX(-50%);
  pointer-events: none;
}
/* .apexcharts-tooltip .arrow_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}*/
.apexcharts-tooltip .arrow_box label {
  padding-bottom: 6px;
}
.apexcharts-tooltip .arrow_box span {
  background: #586d7b;
  padding: 6px 8px;
  border-radius: 8px;
}
.arrow_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  max-width: 180px;
  word-wrap: break-word;
  white-space: normal;
}
.arrow_box::after,
.arrow_box::before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrow_box::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.arrow_box::before {
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: #ccc;
  border-width: 11px;
  margin-left: -11px;
}

.cust_outstandingTbl .customer_tbl .ant-table .ant-table-thead .ant-table-cell {
  background: #7a7b7d;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  position: relative;
  text-align: left;
}
.cust_outstandingTbl .customer_tbl .ant-table .ant-table-tbody .ant-table-cell {
  padding: 10px 15px;
}
.cust_outstandingTbl .customer_tbl .ant-table {
  border-radius: 15px;
}
.cust_outstandingTbl
  .customer_tbl
  .ant-table
  .ant-table-thead
  .ant-table-cell:first-child {
  border-top-left-radius: 15px;
}
.cust_outstandingTbl
  .customer_tbl
  .ant-table
  .ant-table-thead
  .ant-table-cell:last-child {
  border-top-right-radius: 15px;
}
.cust_out_tbl {
  padding-top: 15px;
}
.pay_cust_chart {
  margin-bottom: -30px;
  height: 295px;
  padding-left: 10px;
}
.pay_cust_chart .recharts-layer {
  cursor: pointer;
}
.tbl_summary {
  background: #7a7b7d;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.tbl_summary .ant-table-cell {
  padding: 10px 15px;
  background: #7a7b7d;
  overflow-wrap: normal;
}
.tbl_summary .ant-table-cell:first-child {
  border-bottom-left-radius: 15px;
}
.tbl_summary .ant-table-cell:last-child {
  border-bottom-right-radius: 15px;
}

.payment_card1 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.payment_card1 label {
  font-size: 18px;
  font-weight: bold;
  color: #1e2c36;
}
.payment_card1 .reset_filter_spn {
  font-size: 16px;
  font-weight: bold;
  color: #3cafda;
  cursor: pointer;
}
.mr_12 {
  margin-right: 12px;
}
.left_section_card {
  display: grid;
  text-align: center;
}
.left_section_card img {
  margin: auto;
}
.payment_card1 .card_10 {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #f0f0f0;
  margin: 0;
  position: relative;
  width: 80%;
}
.payment_card1 .card_2 {
  background: #da4453;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #f0f0f0;
  margin: 0;
  position: relative;
  margin-left: 20px;
  width: 18%;
}
.left_section_card .count {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  line-height: 30px;
}
.left_section_card span {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  line-height: 14px;
}
.pb_5 {
  padding-bottom: 5px;
}
.appled_filter {
  text-align: end;
  margin-bottom: -1.5rem;
  padding-right: 10rem;
  font-weight: 500;
  font-size: 16px;
  color: #5f5f5f;
}
.lbl_status label {
  padding: 0px 4px;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
}
.payment_customer_section {
  display: flex;
  justify-content: space-between;
}
.payment_tab .ant-select .ant-select-selector {
  overflow-y: auto;
}
.payment_tab .ant-select .ant-select-selector::-webkit-scrollbar {
  width: 4px;
}
.chart_scroll_section {
  height: 300px;
  overflow: hidden auto;
}
.payment_card1 .card_chart {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #f0f0f0;
  margin: 0;
  position: relative;
  width: 100%;
}
.mt_18 {
  margin-top: 18px;
}

.text_decor {
  text-decoration: none;
}
.payment_table .ant-table-content {
  border-radius: 15px;
}
.payment_table .ant-table-thead .ant-table-cell {
  background-color: #7a7b7d !important;
  color: #fff !important;
}

.total-row .ant-table-cell {
  font-weight: 600 !important;
  background-color: #7a7b7d !important;
  color: #fff !important;
}
.dis_blck_right {
  display: block;
  float: right;
  padding-right: 5px;
}
.dis_blck_right label {
  font-weight: 600;
  color: #5f5f5f;
  font-size: 16px;
  padding-right: 8px;
  padding-top: 4px;
}
.toggle_days.ant-switch-checked {
  background-color: #37bc9b !important;
}

.toggle_days {
  background-color: #da4453 !important;
}

.ant-table-row .tbl_selected_row .ant-table-cell {
  background-color: #e6f7ff !important;
}
.payment_table .ant-table-row {
  cursor: pointer;
}
.payment1.images_bg::before {
  content: "";
  background: url("../images/payment1.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 30px;
  right: -15px;
  width: 45%;
  height: 50%;
  opacity: 0.5;
}
.payment2.images_bg::before {
  content: "";
  background: url("../images/payment2.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 30px;
  right: -15px;
  width: 45%;
  height: 50%;
  opacity: 0.5;
}
.payment3.images_bg::before {
  content: "";
  background: url("../images/paytment3.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 30px;
  right: -15px;
  width: 45%;
  height: 50%;
  opacity: 0.5;
}
.payment4.images_bg::before {
  content: "";
  background: url("../images/payment4.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 30px;
  right: -15px;
  width: 45%;
  height: 50%;
  opacity: 0.5;
}
.outstandingGraphTable {
  justify-content: end;
}
.paymentModule .ant-select-selector .ant-select-selection-overflow {
  margin-left: -10px;
}
.paymentModule .ant-select-selector .ant-select-selection-overflow {
  margin-left: -10px;
}
/* --------Payment Module Deign End----------------- */

/* --------Logistic Module Deign Start----------------- */

.logisticTotal.images_bg::before {
  content: "";
  background: url("../images/logTotalLg.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 25px;
  right: -15px;
  width: 50%;
  height: 55%;
  opacity: 1;
}
.logisticPending.images_bg::before {
  content: "";
  background: url("../images/logPendingLg.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 25px;
  right: -15px;
  width: 50%;
  height: 55%;
  opacity: 1;
}
.logisticTransit.images_bg::before {
  content: "";
  background: url("../images/logTransitLg.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 25px;
  right: -15px;
  width: 50%;
  height: 55%;
  opacity: 1;
}
.logisticDelivered.images_bg::before {
  content: "";
  background: url("../images/logDeliveredLg.png") center center no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  bottom: 25px;
  right: -15px;
  width: 50%;
  height: 55%;
  opacity: 1;
}
.logistic_tables .ant-table .ant-table-content {
  border-radius: 10px 10px 0px 0px;
}

.logistic_tables .ant-table .ant-table-content .ant-table-thead > tr > th {
  position: relative;
  color: #ffffff;
  font-weight: 600;
  text-align: left;
  background: #7a7b7d;
  border-bottom: 1px solid #e3ebf3;
  font-size: 13px;
  padding: 8px 14px;
  cursor: pointer;
}
.logistic_tables .ant-table .ant-table-content .ant-table-tbody > tr {
  background-color: rgba(245, 247, 250, 0.5);
  text-decoration: none;
}
.logistic_tables .ant-table .ant-table-content .ant-table-tbody > tr > td {
  background-color: #fff;
  text-decoration: none;
}
.logistic_tables
  .ant-table
  .ant-table-content
  .ant-table-thead
  > tr
  > th:last-child {
  cursor: default;
}
.logistic_tables .ant-table-tbody > tr.even-row {
  background-color: red !important; /* Ensure this takes effect */
}

.logistic_tables .ant-table-tbody > tr.odd-row {
  background-color: #ffffff !important; /* Ensure this takes effect */
}
/* .even-row {
	background-color: #c8c8c8 !important;
}

.logistic_tables .odd-row {
	background-color: #ffffff;
} */
.logistic_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 9px 15px;
  text-decoration: none;
}
.logistic_tables
  .ant-table
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell
  .status
  label {
  padding: 2px 10px;
  width: fit-content;
  border-radius: 20px;
}
.logistic_tables .pending {
  background-color: #ffe29a;
}
.logistic_tables .dispatch {
  background-color: #55ebc3;
}
.logistic_tables .dispatch {
  background-color: #6dbde1;
}
.logistic_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell p {
  margin-top: 0;
  margin-bottom: 0;
}
.outstandingGraphTable {
  margin-bottom: -2rem;
}
.outstandingGraphTable .custom-table {
  min-width: 300px;
  width: fit-content;
  max-width: 350px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.outstandingGraphTable .custom-table th {
  background-color: #7a7b7d;
  color: white;
  padding: 7px 20px;
  font-weight: bold;
  border: 1px solid #ccc;
  text-align: justify;
}

.outstandingGraphTable .custom-table td {
  padding: 5px 20px;
  border: 1px solid #ccc;
  text-align: justify;
}
.outstandingGraphTable .custom-table tbody {
  font-size: 14px;
}
.outstandingGraphTable .custom-table tbody tr:hover {
  background-color: #f9f9f9;
}
.outstandingGraphTable .custom-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.outstandingGraphTable .custom-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

/* --------Logistic Module Deign End----------------- */

/* --------Visitor Pass Css start----------------- */
.card_text h4 {
  font-size: 19px;
}
.visitorPass .ant-select {
  width: 100%;
}
.visitorPass .ant-select-selector {
  border-radius: 50px !important;
}
.visitorPass .content-body .card {
  min-height: 140px;
}

.visitorPass .ant-select-selector,
.visitorPass .ant-picker {
  border-radius: 20px;
  width: 100%;
}
.transport_type .tab_panel .ant-tabs-nav:before {
  border-bottom: none;
}
.transport_type .tab_panel .ant-tabs-nav-list .ant-tabs-tab {
  background: #fff;
  border: 1px solid #49b2e3;
  border-radius: 100px;
  box-shadow: 0 0 1px 1px #00000014;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-right: 12px;
  min-width: 130px;
}

.transport_type .tab_panel .ant-tabs-nav .ant-tabs-tab-active {
  background: #49b2e3;
}
.transport_type .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.transport_type .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #49b2e3;
}
.transport_type .ant-input {
  border-radius: 20px;
  width: 275px;
}
.visitor_add_details .addButton {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid #1890ff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #1890ff;
}
.gatePassTbl .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 5px 15px;
  text-decoration: none;
}
.gatePassTbl .ant-table .ant-table-thead .ant-table-cell {
  padding: 10px 15px;
}
.table_action_img {
  display: flex;
}
.table_action_img img {
  width: 20px;
}
.table_action_img .line {
  border-left: 2px solid #dddddd;
  height: 20px;
  position: absolute;
  left: 45%;
  top: 7px;
}
.table_action_img .ml-8 {
  margin-left: 18px;
}
.table_action_img .mr-8 {
  margin-left: 18px;
}
.additional-details-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.additional-details-row {
  display: flex;
  align-items: center;
  gap: 12px;
}
.remove_Details {
  width: 25px;
  margin-left: 2px;
  cursor: pointer;
}
.disabled_add_new_btn {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  cursor: not-allowed !important;
}
.gate_pass_section {
  margin: 20px 0 0 0;
  padding: 12px 25px 15px;
  font-size: 14px;
  position: relative;
  background: #f8f8f8;
  box-shadow: 0px 0px 40px #00000012;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.pb_0 {
  padding-bottom: 0;
}
.export_lbl {
  margin-left: 20px;
}
.export_lbl label {
  color: #3cafda;
  font-weight: 500;
  padding-top: 8px;
  cursor: pointer;
}
.export_lbl img {
  width: 20px;
  margin-left: 5px;
}
.export_lbl_Mobile img {
  width: 30px;
  margin-left: 10px;
  padding-top: 16px;
}
.reset_save_btn {
  display: flex;
  justify-content: end;
  padding-top: 20px;
}
.reset_save_btn .ant-btn {
  width: 110px;
  height: 35px;
  border-radius: 20px;
}
.reset_save_btn .ant-btn:hover,
.reset_save_btn .ant-btn:focus {
  background-color: #536d80;
  color: #fff;
}
.reset_save_btn .reset_btn {
  border-color: #536d80;
  color: #536d80;
}
.reset_save_btn .save_btn {
  background-color: #536d80;
  color: #fff;
  margin-left: 15px;
  border-color: #536d80;
}
.vertical_line {
  border: 0.5px solid #c8c8c8;
  margin-top: 15px;
}
.visitor_reports {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.report_section {
  display: flex;
}
.report_section label {
  padding-top: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #1e2c36;
}
.report_section .resport_date {
  margin-left: 30px;
  width: 175px;
  height: 35px;
}
.report_section .resport_select {
  width: 175px;
  margin-left: 20px;
}
.report_section .resport_select .ant-select-selector {
  height: 35px;
}
.report_save_btn .ant-btn {
  width: 110px;
  height: 35px;
  border-radius: 20px;
  background-color: #536d80;
  color: #fff;
  margin-left: 20px;
  border-color: #536d80;
}
.report_pdf label {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #3cafda;
  padding-top: 10px;
}
.report_pdf img {
  width: 20px;
  margin-left: 5px;
}
.brdr_20 {
  border-radius: 20px;
}
.inputError {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 0px;
}
.btn_cancel_mdl {
  width: 110px;
  height: 35px;
  border-radius: 20px;
  border-color: #536d80;
  color: #536d80;
}
.btn_delete_mdl {
  width: 110px;
  height: 35px;
  border-radius: 20px;
  background-color: #536d80;
  color: #fff;
  margin-left: 15px;
  border-color: #536d80;
}
.btn_delete_mdl:active,
.btn_delete_mdl:hover {
  background-color: #536d80;
  color: #fff;
  border-color: #536d80;
}
.delete_modal .ant-modal-content {
  border-radius: 12px;
}
.delete_modal .ant-modal-header {
  border-radius: 12px;
}
.delete_modal .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #1e2c36;
}
.delete_modal .ant-modal-body p {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}
/* --------Visitor Pass Css End----------------- */

@media (max-width: 767px) {
  .timeline .event-date {
    position: absolute;
    top: -38px;
    left: 44.5%;
    padding: 5px;
    min-width: 66px;
    margin: 0 auto;
    z-index: 2;
  }
  .main-timeline {
    position: relative;
    padding-top: 1rem !important;
  }
  .main-timeline::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #939597;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  .timeline-item::before {
    display: none;
  }
  .timeline-with-icons {
    border-left: 0px;
  }
  .timeline-item .timeline-icon {
    position: relative;
    margin-bottom: -12px;
    z-index: 2;
  }
  .timeline-item .timeline-icon:hover {
    top: -22%;
    left: 47.3%;
    transform: scale(1.1);
    width: 35px;
    height: 35px;
    transition: all ease 0.3s;
  }

  .timeline-with-icons .timeline-icon {
    top: -22%;
    left: 46%;
    /* position: absolute; */
    background-color: hsl(217, 88.2%, 90%);
    color: hsl(217, 88.8%, 35.1%);
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timeline-with-icons {
    padding: 0px;
  }
  .pay_cust_chart {
    height: 260px;
  }
}

@media (max-width: 320px) {
  .sign-div {
    margin-top: 0px;
  }
}

@media (max-width: 420px) {
  .tab_searchtxt .ant-input-group-wrapper {
    width: 160px;
    margin-top: 14px;
  }
  .tab_report_searchtxt .ant-input-group-wrapper {
    width: 185px;
    margin-top: 14px;
  }
  .tab_report_searchtxt .ant-input-affix-wrapper {
    padding: 5.8px 10px;
  }
  .timeline-with-icons .timeline-icon {
    top: -22%;
    left: 45%;
  }
  .timeline .event-date {
    left: 39.5%;
    top: -32px;
  }

  .timeline-item .timeline-icon:hover {
    top: -22%;
    left: 43.3%;
    transform: scale(1.1);
    width: 35px;
    height: 35px;
    transition: all ease 0.3s;
  }
}

@media screen and (min-width: 350px) and (max-width: 370px) {
  .tab_report_searchtxt .ant-input-group-wrapper {
    width: 180px;
    margin-top: 14px;
  }
}
@media screen and (min-width: 320px) and (max-width: 350px) {
  .tab_report_searchtxt .ant-input-group-wrapper {
    width: 150px;
    margin-top: 14px;
  }
  .tab_report_searchtxt .ant-input-affix-wrapper {
    padding: 5.6px 10px;
  }
}

@media (max-width: 322px) {
  .timeline .event-date {
    left: 35.5%;
    top: -38px;
  }
  .timeline-with-icons .timeline-icon {
    top: -22%;
    left: 43.5%;
  }
  .gate_pass_section {
    padding: 12px 20px 15px;
  }
  .tab_report_searchtxt .ant-input-group-wrapper {
    width: 150px;
    margin-top: 14px;
  }
}

@media screen and (min-width: 300px) and (max-width: 320px) {
  .tab_report_searchtxt .ant-input-group-wrapper {
    width: 140px;
    margin-top: 14px;
  }
  .tab_report_searchtxt .ant-input-affix-wrapper {
    padding: 5.8px 10px;
  }
  .export_lbl_Mobile img {
    width: 25px;
    padding-top: 20px;
  }
}
@media (max-width: 646px) {
  .material_count {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .dashboard .ant-select {
    width: 111%;
  }
  .outstandingGraphTable {
    margin-bottom: 1rem;
    margin-top: 1rem;
    overflow: auto;
  }
  .payment_customer_section {
    display: block;
  }
  .material_label {
    font-size: 12px;
    margin-bottom: 5px;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .material_count {
    font-size: 12px;
  }

  .material_label > span {
    padding-top: 3px !important;
  }

  .sign-div {
    max-height: 50px;
    margin-bottom: -10px;
    margin-top: 20px;
    padding-top: 0px;
    width: 100%;
  }

  .sign-logo {
    height: 60%;
    margin: -7px;
  }

  .custom-dropdown {
    flex-direction: column !important;
    margin-bottom: 10px;
    width: 90%;
  }

  .custom-label {
    width: 100% !important;
    margin-bottom: 2px !important;
  }
  .col-md-3 {
    width: 100%;
  }
  .col-md-4 {
    width: 100%;
    margin-bottom: 25px;
  }
  .material_tabs
    .ant-tabs
    .ant-tabs-nav
    .ant-tabs-tab-active
    .ant-tabs-tab-btn {
    font-size: 16px;
  }
  .material_tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab-btn {
    font-size: 16px;
  }
  .margin_b_20 {
    margin-bottom: 20px;
  }
  .overview_text {
    padding: 12px;
  }
  .overview_text h3 {
    font-size: 24px;
  }
  .overview_text span {
    font-size: 18px;
  }
  .dis_blck_right {
    float: none;
    padding-bottom: 10px;
  }
  .col-12-pt-15 {
    padding-top: 15px;
  }
  .visitorPass .ant-select,
  .visitorPass .ant-picker {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .report_section {
    flex-direction: column;
  }
  .securityReport .reset_save_btn .save_btn,
  .receptionReport .reset_save_btn .save_btn {
    margin-left: 0px;
  }
  .securityReport .reset_save_btn,
  .receptionReport .reset_save_btn,
  .receptionReport .report_pdf {
    justify-content: flex-start !important;
  }
  .table_action_img img {
    width: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .overview_tab_cards {
    height: 100%;
    max-height: 122px;
  }
  .payment_tab .col-md-8 {
    width: 100%;
  }
  .dis_blck_right {
    float: none;
    padding-bottom: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1366px) {
  .payment_card1 .invoice_out .ant-select-arrow {
    top: 12%;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .content .content-wrapper {
    padding: 3rem 2rem;
  }
  .dashboard_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
    font-size: 12px;
  }
  .logistic_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
    font-size: 12px;
  }
  .delay_status_code label {
    font-size: 11px;
  }
  .payment_tab .col-md-8 {
    width: 100%;
  }
  .payment_card1 .card_10 {
    width: 73%;
  }
  .payment_card1 .card_2 {
    width: 25%;
  }
  .payment_card1 label {
    font-size: 15px;
  }
  .payment_card1 .reset_filter_spn {
    font-size: 15px;
  }
  .payment_card1 .reset_filter_spn img {
    width: 15px;
  }

  .payment_tab .analy_type {
    font-size: 16px;
  }
  .payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .pay_search_int .ant-input-group-wrapper {
    width: 200px;
  }
  .pay_customer_tab .card_lbl {
    font-size: 14px;
  }
  .payment_card1 .invoice_out {
    display: flex;

    padding-left: 0px;
  }
}
@media (max-width: 1024px) {
  .card_text_img_size h3 {
    font-size: 20px;
  }
  .card_text_img_size span {
    font-size: 16px;
    line-break: auto;
  }
  .card_text_img_size img {
    width: 90%;
  }
  /* .sign-logo {
    height: 47%;
    margin: -4px;
} */
  .pt-30 {
    padding-top: 0;
  }
  .wd_80 {
    width: 80% !important;
  }
  .overview_text h3 {
    font-size: 16px;
  }
  .overview_text span {
    font-size: 15px;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .content .content-wrapper {
    padding: 3rem 1.5rem;
  }
  .content .content-wrapper .content-header-title {
    font-size: 18px;
    margin-top: 8px;
  }
  .sign-logo {
    height: 125%;
    margin: -35px 0 -6px 0px;
  }
  .delay_status_code label {
    font-size: 11px;
  }
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    left: -15px !important;
  }
  .delivery_mate_lbl {
    font-size: 10px;
    line-height: 15px;
    padding-top: 30px;
  }
  .chart_left_lbl {
    font-size: 11px;
  }
  .lbl_pad {
    font-size: 10px;
  }
  .upload_docts {
    width: 100px;
    font-size: 12px;
    padding: 4px 8px;
  }
  .tab_searchtxt .ant-input-group-wrapper {
    width: 160px;
  }
  .tab_report_searchtxt .ant-input-group-wrapper {
    width: 210px;
    margin-top: 12px;
  }
  .export_lbl_Mobile img {
    margin-left: 20px;
  }
  .appled_filter {
    font-size: 13px;
  }
  .payment_card1 .card_10 {
    width: 100%;
  }
  .payment_card1 .card_2 {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }
  .payment_card1 label {
    font-size: 14px;
  }
  .payment_card1 .reset_filter_spn {
    font-size: 14px;
  }
  .payment_customer_section {
    display: block;
  }
  .payment_tab .analy_type {
    font-size: 12px;
  }
  .payment_tab .ant-select .ant-select-selector {
    height: 35px;
    padding: 2px 14px;
    font-size: 14px;
    width: 160px;
    min-width: 160px;
  }
  .payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab {
    height: 36px;
    min-width: 100px;
  }
  .payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 13px;
  }
  .pay_customer_tab .card_lbl {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .cust_outstandingTbl
    .customer_tbl
    .ant-table
    .ant-table-thead
    .ant-table-cell {
    font-size: 12px;
  }
  .cust_outstandingTbl
    .customer_tbl
    .ant-table
    .ant-table-tbody
    .ant-table-cell {
    font-size: 12px;
  }
  .tbl_summary .ant-table-cell {
    font-size: 12px;
  }
  .dis_blck_right {
    float: none;
    padding-bottom: 10px;
  }
  .payment_card1 .invoice_out {
    display: flex;
    margin-top: 20px;
    padding-left: 10px;
  }
}
@media screen and (min-width: 370px) and (max-width: 420px) {
  .content .content-wrapper {
    padding: 3rem 1.5rem;
  }
  .content .content-wrapper .content-header-title {
    font-size: 16px;
    margin-top: 10px;
  }
  .sign-logo {
    height: 125%;
    margin: -35px 0 -6px 0px;
  }
  .delay_status_code label {
    font-size: 9px;
  }
  .delay_status_code label p {
    font-size: 9px;
  }
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    left: 22px !important;
  }
  .delivery_mate_lbl {
    font-size: 10px;
    line-height: 15px;
    padding-top: 30px;
  }
  .dashboard_tables .ant-table .ant-table-content .ant-table-thead > tr > th,
  .logistic_tables .ant-table .ant-table-content .ant-table-thead > tr > th {
    font-size: 11px;
  }
  .dashboard_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell,
  .logistic_tables .ant-table .ant-table-tbody .ant-table-row .ant-table-cell {
    font-size: 11px;
  }
  .global_filter_btn {
    padding: 4rem 1.5rem;
  }
  .chart_left_lbl {
    font-size: 11px;
    line-height: 22px;
    padding-top: 25px;
  }
  .lbl_pad {
    font-size: 8px;
  }
  .upload_docts {
    font-size: 12px;
  }
  .dashboard_tabs .ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
    font-size: 14px;
  }
  .appled_filter {
    font-size: 14px;
  }
  .payment_card1 .card_10 {
    width: 100%;
  }
  .payment_card1 .card_2 {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }
  .payment_card1 label {
    font-size: 14px;
  }
  .payment_card1 .reset_filter_spn {
    font-size: 14px;
  }
  .payment_customer_section {
    display: block;
  }
  .payment_tab .analy_type {
    font-size: 16px;
  }
  .payment_tab .ant-select .ant-select-selector {
    height: 35px;
    padding: 2px 14px;
    font-size: 14px;
    width: 130px;
    min-width: 130px;
  }
  .payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab {
    height: 36px;
    min-width: 100px;
  }
  .payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 13px;
  }
  .payment_card1 .invoice_out {
    display: flex;
    margin-top: 20px;
    padding-left: 10px;
  }
  .pay_customer_tab .card_lbl {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .cust_outstandingTbl
    .customer_tbl
    .ant-table
    .ant-table-thead
    .ant-table-cell {
    font-size: 12px;
  }
  .cust_outstandingTbl
    .customer_tbl
    .ant-table
    .ant-table-tbody
    .ant-table-cell {
    font-size: 12px;
  }
  .tbl_summary .ant-table-cell {
    font-size: 12px;
  }
  .pay_section_3 {
    padding: 10px 25px 0px;
    font-size: 16px;
  }
  .dis_blck_right {
    float: none;
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 369px) {
  .content .content-wrapper {
    padding: 2.5rem 1.5rem;
  }
  .content .content-wrapper .content-header-title {
    font-size: 13px;
    margin-top: 12px;
  }
  .details_view_title .back_btn {
    width: 80px;
    padding: 4px;
  }
  .sign-div {
    margin-top: 0px;
  }
  .material_tabs
    .ant-tabs
    .ant-tabs-nav
    .ant-tabs-tab-active
    .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .material_tabs .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .card_text_img_size img {
    width: 70% !important;
  }
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    left: -20px !important;
  }
  .delivery_mate_lbl {
    font-size: 8px;
    line-height: 12px;
    padding-top: 3rem;
  }
  .chart_left_lbl {
    font-size: 12px;
    line-height: 22px;
    padding-top: 25px;
  }
  .overview_text h3 {
    font-size: 22px;
  }
  .overview_text span {
    font-size: 14px;
  }
  .payment_card1 .card_10 {
    width: 100%;
  }
  .payment_card1 .card_2 {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }
  .payment_card1 label {
    font-size: 12px;
  }
  .payment_card1 .reset_filter_spn {
    font-size: 12px;
  }
  .payment_card1 .reset_filter_spn img {
    width: 14px;
  }
  .payment_customer_section {
    display: block;
  }
  .payment_tab .analy_type {
    font-size: 12px;
  }
  .payment_tab .ant-select .ant-select-selector {
    height: 32px;
    padding: 1px 14px;
    font-size: 13px;
    width: 140px;
    min-width: 140px;
  }
  .pay_search_int .ant-input-group-wrapper {
    width: 190px;
  }
  .payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab {
    height: 36px;
    min-width: 100px;
  }
  .payment_tab .tab_panel .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 13px;
  }
  .pay_customer_tab .card_lbl {
    font-size: 13px;
    padding-bottom: 10px;
  }
  .cust_outstandingTbl
    .customer_tbl
    .ant-table
    .ant-table-thead
    .ant-table-cell {
    font-size: 10px;
  }
  .cust_outstandingTbl
    .customer_tbl
    .ant-table
    .ant-table-tbody
    .ant-table-cell {
    font-size: 10px;
  }
  .tbl_summary .ant-table-cell {
    font-size: 10px;
  }
  .dis_blck_right {
    float: none;
    padding-bottom: 10px;
  }
  .payment_card1 .invoice_out {
    /* display: flex; */
    margin-top: 10px;
    padding-left: 10px;
  }
  .payment_card1 .invoice_out .pl-20 {
    padding-left: 0px;
    margin-top: 5px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1189px) {
  .font24 {
    font-size: 18px;
  }
  .card_counts h5 {
    font-size: 15px;
  }
}
@media screen and (min-width: 1189px) and (max-width: 1270px) {
  .card_counts h5 {
    font-size: 12px;
  }
}
@media screen and (min-width: 1189px) and (max-width: 1370px) {
  .card_counts h5 {
    font-size: 12px;
  }
}
@media (max-width: 820px) {
  .col-md-3 {
    width: 100%;
  }
  .col-md-4 {
    width: 100%;
    margin-bottom: 25px;
  }

  .sign-div {
    max-height: 50px;
    margin-bottom: -10px;
    margin-top: 20px;
    padding-top: 0px;
    width: 100%;
  }

  .sign-logo {
    height: 60%;
    margin: -7px;
  }
  .outstandingGraphTable {
    justify-content: start;
  }
}
@media (max-width: 992px) {
  .custom-label {
    width: auto;
  }
}
@media (max-width: 2560px) {
  .pt-30 {
    padding-top: 0;
  }
}
.apexcharts-xaxis-label {
  display: block;
  text-align: center;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  max-width: 600px;
  margin: auto;
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.tag-container {
  display: flex;
  overflow-x: auto;
}

.chart_tag {
  display: inline-flex;
  align-items: center;
  background-color: #1e2a39;
  border-radius: 20px;
  padding: 3px 10px;
  margin: 5px 5px 5px 0px;
  color: #fff;
  font-size: 12px;
  margin-left: "0px";
}

.tag-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  margin-right: 8px;
}

.close-btn {
  font-size: 12px;
  cursor: pointer;
  padding-left: 8px;
  border-left: 1px solid #97a4ae;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.close-btn:hover {
  color: #ff6b6b;
}
.payment_custom_tooltip {
  padding: 10px;
}
.tile_cards {
  display: grid;
  position: relative;
  z-index: 1;
}
